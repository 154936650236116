import { useState } from "react";
import tmp from "../../asset/img/bg/bg3.webp";

export const Terms = () => {
  const [buka, setBuka] = useState(0);
  const handleOpen = (value) => {
    setBuka(buka === value ? 0 : value);
  };

  return (
    <div className="w-full h-full">
      <div className="justify-center items-center bg-blue-gray-100">
        <div className="relative top-0 lg:pt-32 lg:px-24 px-10 py-10 scroll-pt-px bg-center">
          <div
            className="flex text-center flex-col"
            data-aos="flip-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <h2
              className="md:text-4xl my-5 md:my-2 text-2xl"
              style={{ color: "#28a745", fontFamily: "sans-serif" }}
            >
              Terms & Conditions
            </h2>
            <p
              className="mt-3 text-sm md:text-lg"
              style={{ color: "#343a40", fontFamily: "sans-serif" }}
            >
              Dengan menggunakan WrkPln, Anda menyetujui untuk terikat pada
              syarat dan ketentuan layanan yang berlaku. WrkPln dapat
              memperbarui atau mengubah syarat dan ketentuan ini kapan saja
              tanpa pemberitahuan sebelumnya, termasuk untuk fitur baru yang
              menambah atau meningkatkan layanan. Melanjutkan penggunaan
              aplikasi setelah perubahan berarti Anda setuju dengan pembaruan
              tersebut. WrkPln berhak menghentikan akun Anda jika Anda melanggar
              ketentuan ini. Meskipun WrkPln melarang konten atau tindakan
              tertentu di dalam layanan, Anda memahami bahwa WrkPln tidak
              bertanggung jawab atas konten yang dikirimkan oleh pengguna lain,
              dan Anda menggunakan layanan ini sepenuhnya dengan risiko Anda
              sendiri.
            </p>
            <p
              className="mt-3 text-sm md:text-lg"
              style={{ color: "#343a40", fontFamily: "sans-serif" }}
            >
              Selain itu, dengan menggunakan aplikasi ini, Anda menyetujui
              pengumpulan dan penggunaan data geolokasi sesuai Kebijakan Privasi
              WrkPln. Data lokasi yang dikumpulkan, seperti lokasi akurat (GPS)
              atau perkiraan (Wi-Fi/jaringan seluler), digunakan untuk
              menyediakan layanan berbasis lokasi, meningkatkan performa
              aplikasi, dan memberikan konten yang relevan. Anda bertanggung
              jawab untuk menggunakan layanan ini secara sah dan tidak untuk
              tujuan yang melanggar hukum. WrkPln tidak menjamin akurasi atau
              ketersediaan data lokasi, karena tergantung pada layanan pihak
              ketiga, dan tidak bertanggung jawab atas kesalahan atau
              keterbatasan layanan tersebut. WrkPln juga berhak mengubah atau
              menghentikan layanan geolokasi kapan saja tanpa pemberitahuan.
              Jika Anda tidak setuju dengan syarat ini.
            </p>
            {/* <p
              className="mt-3 text-sm md:text-lg"
              style={{ color: "#343a40", fontFamily: "sans-serif" }}
            >
              Pelanggaran atas persyaratan apapun di bawah ini dapat berakibat
              pada penghentian pelayanan Akun Anda. Meskipun WrkPln melarang
              perlakuan dan konten tersebut dalam Layanan, Anda mengerti dan
              setuju bahwa WrkPln tidak bertanggung jawab terhadap konten yang
              dikirim ke Layanan dan Anda mungkin saja terekspos dengan materi
              tersebut. Anda setuju untuk menggunakan Layanan dengan resiko Anda
              sendiri.
            </p>
            <p
              className="mt-3 text-sm md:text-lg"
              style={{ color: "#343a40", fontFamily: "sans-serif" }}
            >
              Dengan menggunakan aplikasi ini, Anda menyetujui pengumpulan,
              penggunaan, dan pengelolaan data geolokasi Anda sesuai dengan
              Kebijakan Privasi kami. Data geolokasi, yang dapat mencakup
              informasi lokasi akurat (GPS) atau perkiraan (Wi-Fi/jaringan
              seluler), digunakan untuk menyediakan layanan berbasis lokasi,
              meningkatkan performa aplikasi, dan memberikan konten yang relevan
              dengan lokasi Anda. Anda memberikan izin kepada aplikasi ini untuk
              mengakses data lokasi melalui pengaturan perangkat Anda, dengan
              pemahaman bahwa keakuratan data bergantung pada layanan pihak
              ketiga seperti GPS atau jaringan seluler, yang mungkin tidak
              selalu tersedia di semua area. Anda bertanggung jawab untuk
              menggunakan layanan ini secara sah dan tidak untuk tujuan yang
              melanggar hukum, membahayakan, atau melanggar hak orang lain. Kami
              tidak bertanggung jawab atas kesalahan dalam data lokasi atau
              keterbatasan layanan geolokasi. Dengan ini, Anda menyetujui bahwa
              kami dapat mengubah atau menghentikan layanan geolokasi kapan saja
              tanpa pemberitahuan sebelumnya. Jika Anda tidak setuju dengan
              syarat ini, harap hentikan penggunaan aplikasi.
            </p> */}
          </div>
        </div>
      </div>
      <div className="w-full h-full">
        <div
          className="relative py-4 bg-fixed bg-cover bg-center"
          style={{ backgroundImage: `url(${tmp})` }}
          loading="lazy"
        >
          <div className="flex flex-col gap-3 items-center min-w-fit lg:px-28 lg:w-auto">
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(1)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md lg:text-lg text-sm w-full py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Persetujuan</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 1 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 1 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Anda dengan ini menyatakan &amp; menjamin bahwa:
                      <ul className="list-disc ml-5">
                        <li>
                          Anda telah membaca dan menyetujui Syarat &amp;
                          Ketentuan, Kebijakan Privasi, dan Ketentuan Biaya
                          kami;
                        </li>
                        <li>
                          Anda akan menggunakan dan/atau mengakses Situs,
                          Layanan, Konten Kami hanya untuk tujuan yang sah;
                        </li>
                        <li>
                          Anda bertanggung jawab untuk menjaga keamanan akun
                          &amp; kata sandi Anda;
                        </li>
                        <li>
                          Anda wajib memastikan keakuratan, data yang Anda
                          masukkan pada sistem;
                        </li>
                        <li>
                          Anda bertanggung jawab atas semua konten yang dikirim
                          &amp; aktivitas yang terjadi dalam akun Anda (meskipun
                          konten dikirim oleh orang lain yang memiliki akun di
                          bawah akun Anda)
                        </li>
                      </ul>
                    </li>
                    <li>
                      Anda dengan ini menyatakan dan menjamin bahwa:
                      <ul className="list-disc ml-5">
                        <li>
                          Sistem yang diberikan oleh WrkPln sudah sesuai dengan
                          keinginan dan kebutuhan perusahaan Anda &amp;
                          karenanya Anda melepaskan WrkPln dari segala kerugian
                          dan/atau tuntutan yang timbul akibat ketidaksesuaian
                          sistem WrkPln dengan kebutuhan perusahaan Anda
                        </li>
                        <li>
                          WrkPln berhak untuk mengubah, memodifikasi, menunda
                          atau menghentikan Layanan (atau bagian apapun dari
                          Layanan) kapanpun &amp; dari waktu ke waktu, baik
                          untuk sementara atau untuk selama-lamanya dengan atau
                          tanpa pemberitahuan
                        </li>
                      </ul>
                    </li>
                    <li>
                      WrkPln tidak menjamin bahwa:
                      <ul className="list-disc ml-5">
                        <li>
                          Layanan akan memenuhi kebutuhan Anda secara spesifik
                        </li>
                        <li>
                          Layanan tidak akan terganggu, tepat waktu, aman, atau
                          terbebas dari kesalahan
                        </li>
                        <li>
                          Hasil yang diperoleh dari penggunaan Layanan akan
                          akurat atau terpercaya
                        </li>
                        <li>
                          Kualitas produk, layanan, informasi, atau materi lain
                          yang telah Anda beli dan dapatkan melalui Layanan
                          dapat memenuhi harapan Anda
                        </li>
                        <li>Semua kesalahan dalam Layanan akan diperbaiki.</li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="container">
              <div
                className="w-full px-3"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                <button
                  onClick={() => handleOpen(2)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md lg:text-lg text-sm w-full py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Ketentuan Penggunaan layanan &amp; Aplikasi</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 2 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 2 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Anda harus memberikan nama lengkap Anda, alamat email, dan
                      informasi lain yang diminta untuk melengkapi proses
                      pendaftaran
                    </li>
                    <li>
                      Login Anda hanya dapat digunakan oleh satu orang, tidak
                      diperbolehkan menggunakan satu login untuk beberapa orang
                      sekaligus. Anda dapat membuat login secara terpisah
                      sebanyak yang Anda inginkan
                    </li>
                    <li>
                      Penggunaan Layanan Anda merupakan resiko Anda sendiri.
                    </li>
                    <li>
                      Layanan diberikan atas dasar “sebagaimana adanya” dan
                      “sebagaimana tersedia.”
                    </li>
                    <li>
                      Dukungan teknis hanya tersedia melalui email. Alamat email
                      teknis adalah{" "}
                      <span className="underline text-green-700">
                        support@greenhcm.com
                      </span>
                    </li>
                    <li>
                      WrkPln tidak mengklaim hak kekayaan intelektual dari
                      materi yang Anda berikan ke Layanan. Profil dan materi
                      yang Anda unggah tetap menjadi milik Anda
                    </li>
                    <li>
                      Anda memberikan izin kepada WrkPln untuk menggunakan nama
                      perusahaan atau logo Anda untuk dicantumkan sebagai klien
                      atau pengguna dengan tujuan pemasaran kecuali Anda telah
                      menonaktifkan akun Anda dan/atau memberitahu Kami untuk
                      menghapus nama perusahaan dan logo Anda dari halaman
                      pemasaran.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="container">
              <div
                className="w-full px-3"
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                <button
                  onClick={() => handleOpen(3)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full lg:text-lg text-sm py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>
                    Ketentuan informasi yang kami kumpulkan &amp; Simpan
                  </span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 3 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 3 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <span className="text-gray-900 font-bold">
                      Kami tidak mengklaim kepemilikan atas konten yang Anda
                      isi.
                    </span>
                    <li>
                      Ketika Anda mendaftar untuk, atau akses, akun WrkPln, kami
                      dapat mengumpulkan beberapa informasi pribadi yang dapat
                      digunakan untuk menghubungi atau mengenali Anda (
                      “Informasi Pribadi”), seperti nama, nomor telepon, alamat
                      email, alamat rumah dan bisnis.
                    </li>
                    <li>
                      Layanan ini juga dapat digunakan oleh atasan Anda untuk
                      menyimpan informasi yang berhubungan dengan pekerjaan
                      tertentu lainnya (“Informasi Pekerjaan”).
                    </li>
                    <li>
                      Informasi Pekerjaan tersebut mungkin termasuk status
                      pekerjaan, informasi rekening, informasi manfaat dan
                      kontak darurat. Informasi Pekerjaan ini dienkripsi dan
                      disimpan di server aman dan hanya dapat diakses oleh orang
                      atau orang yang ditunjuk oleh direksi perusahaan sebagai
                      administrator akun.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(4)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full lg:text-lg text-sm py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Ketentuan Layanan Percobaan</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 4 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 4 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Perusahaan dapat memperoleh layanan percobaan akses
                      menggunakan aplikasi WrkPln bebas biaya maksimal selama 30
                      (tiga puluh) hari kalender.
                    </li>
                    <li>
                      Layanan percobaan diberikan kepada Perusahaan yang telah
                      disetujui oleh pihak WrkPln.
                    </li>
                    <li>
                      Layanan percobaan tersebut diberikan dengan maksud untuk
                      membantu Perusahaan untuk mengambil keputusan apakah akan
                      menjadi User berlangganan aplikasi WrkPln.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(5)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md lg:text-lg text-sm w-full py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Ketentuan Jangka Waktu Berlangganan</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 5 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 5 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Perusahaan dapat berlangganan aplikasi WrkPln untuk jangka
                      waktu minimal 6 (enam) bulan.
                    </li>
                    <li>
                      Apabila Perusahaan ingin berlangganan aplikasi WrkPln
                      lebih dari 6 (enam) bulan, maka perusahaan dapat
                      berkomunikasi dengan pihak WrkPln untuk menuangkan
                      kesepakatan berlangganan ke dalam Perjanjian Kerja Sama.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(6)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full lg:text-lg text-sm py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>
                    Ketentuan Pembayaran, Pengembalian Dana, Peningkatan dan
                    Penurunan Layanan
                  </span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 6 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 6 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Pembayaran yang kami dukung saat ini menggunakan kartu
                      kredit &amp; Transfer antar Bank
                    </li>
                    <li>
                      Pada tahap awal Anda membuat akun, kami akan membuatkan
                      virtual akun bank BCA, BNI, BRI &amp; Mandiri khusus untuk
                      segala transaksi perusahaan Anda. Sistem kami akan
                      mengkonfirmasi secara otomatis setiap transaksi.
                    </li>
                    <li>
                      Tagihan Layanan dikenakan setiap akhir bulan terhitung
                      selama 1 bulan pemakaian
                    </li>
                    <li>
                      Tidak ada pengembalian dana atau piutang untuk layanan
                      bulanan yang tidak selesai, pengembalian dana
                      peningkatan/penurunan Layanan, atau pengembalian dana
                      untuk bulan yang tidak terpakai dengan akun terbuka.
                    </li>
                    <li>
                      Tidak ada pengecualian untuk hal tersebut di atas, supaya
                      semuanya mendapat perlakuan yang sama.
                    </li>
                    <li>
                      Untuk setiap peningkatan atau penurunan dalam tingkatan
                      paket program, sistem akan secara otomatis merubah
                      pembayaran dengan paket terbaru yang digunakan.
                    </li>
                    <li>
                      Menurunkan Layanan Anda dapat mengakibatkan kehilangan
                      konten, fitur, atau kapasitas akun Anda. WrkPln tidak
                      bertanggung jawab atas kehilangan tersebut.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(7)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full lg:text-lg text-sm py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Ketentuan Pembatalan atau Penghentian</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 7 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 7 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Anda bertanggung jawab sepenuhnya untuk membatalkan akun
                      Anda dengan cara yang benar.
                    </li>
                    <li>
                      Anda diharuskan menghubungi Team kami untuk proses
                      penghentian layanan.
                    </li>
                    <li>
                      Seluruh konten Anda akan dihapus setelah pembatalan
                      Layanan. Proses ini akan memakan waktu beberapa jam hingga
                      beberapa hari. Informasi ini tidak dapat dipulihkan
                      setelah akun Anda dibatalkan. Hanya Pemilik Akun (Account
                      Owner) yang dapat melakukan penghapusan akun.
                    </li>
                    <li>
                      Apabila Anda membatalkan Layanan dan masih memiliki
                      pembayaran yang terhitung di sistem kami, pembatalan Anda
                      akan diproses setelah Anda melunasi segala pembayaran yang
                      ada.
                    </li>
                    <li>
                      WrkPln, berdasarkan kebijakannya sendiri, berhak untuk
                      menangguhkan atau menghentikan akun Anda dan menolak
                      segala bentuk penggunaan Layanan baik pada saat itu maupun
                      di masa yang akan datang dengan alasan apapun dan
                      kapanpun. Penghentian Layanan tersebut akan berakibat pada
                      penonaktifan atau penghapusan akun atau akses Anda ke akun
                      Anda, dan kehilangan serta penyerahan seluruh Konten dalam
                      Akun Anda.
                    </li>
                    <li>
                      WrkPln berhak untuk menolak memberikan layanan kepada
                      siapapun dengan alasan apapun dan kapanpun.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(8)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md lg:text-lg text-sm w-full py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Ketentuan Penggunaan Informasi Anda</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 8 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 8 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Informasi pribadi dan data yang dikumpulkan dari cookie
                      dan data logging lain dapat digunakan untuk:
                      <ul className="list-disc ml-5">
                        <li>
                          Memberikan dan meningkatkan Layanan kami dan layanan
                          pelanggan.
                        </li>
                        <li>Mengelola penggunaan Layanan.</li>
                        <li>Lebih memahami kebutuhan Anda.</li>
                        <li>
                          Mempersonalisasikan dan meningkatkan pengalaman Anda.
                        </li>
                        <li>
                          Memberikan atau menawarkan pembaruan perangkat lunak
                          dan pengumuman produk.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Informasi tertentu dienkripsi dan disimpan dengan layanan
                      penyimpanan dan dapat diakses dan diunduh oleh Anda dan
                      administrator akun Anda.
                    </li>
                    <li>
                      WrkPln tidak mengakses atau menggunakan Informasi
                      Pekerjaan atau file Anda selain dalam bentuk terenkripsi
                      atau dalam laporan agregat yang tidak mengandung, atau
                      yang dapat digunakan untuk mengekstrak, informasi pribadi.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(9)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md lg:text-lg text-sm w-full py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Ketentuan Berbagi Informasi &amp; Pengungkapan</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 9 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 9 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Kami tidak menjual informasi pribadi Anda, File atau
                      Informasi Pekerjaan kepada pihak ketiga.
                    </li>
                    <li>
                      Kami dapat menggunakan perusahaan pihak ketiga tertentu
                      terpercaya dan individu untuk membantu kami menyediakan,
                      menganalisis, dan meningkatkan Layanan (termasuk namun
                      tidak terbatas pada penyimpanan data, pemeliharaan,
                      manajemen database, analisis web, proses pembayaran, dan
                      perbaikan fitur Layanan).
                    </li>
                    <li>
                      Pihak ketiga ini mungkin memiliki akses ke informasi Anda
                      hanya untuk tujuan melakukan tugas-tugas tertentu atas
                      nama kami dan di bawah kewajiban sama dengan yang di
                      Kebijakan Privasi kami.
                    </li>
                    <li>
                      Kami dapat mengungkapkan kepada pihak luar berkas,
                      Informasi Pribadi dan/atau Informasi Pekerjaan yang
                      disimpan di WrkPln dan Informasi tentang Anda yang kami
                      kumpulkan ketika kami memiliki keyakinan bahwa
                      pengungkapan memang diperlukan untuk:
                      <ul className="list-disc ml-5">
                        <li>
                          Mematuhi hukum, peraturan atau permintaan hukum wajib.
                        </li>
                        <li>
                          Melindungi keselamatan seseorang dari kematian atau
                          luka fisik yang serius.
                        </li>
                        <li>
                          Mencegah penipuan atau penyalahgunaan WrkPln, Jasa
                          atau penggunanya.
                        </li>
                        <li>
                          Untuk melindungi hak kekayaan WrkPln ini. Apabila kami
                          menyediakan berkas Anda ke lembaga penegak hukum
                          sebagaimana dimaksud di atas, kami akan menghapus
                          enkripsi WrkPln ini dari berkas sebelum memberikannya
                          untuk penegakan hukum.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Apabila kami terlibat dalam merger, akuisisi, atau
                      penjualan dari semua atau sebagian dari aset kami,
                      informasi Anda dapat ditransfer sebagai bagian dari
                      transaksi itu, tapi kami akan memberitahu Anda (misalnya,
                      melalui email dan/atau pemberitahuan yang jelas di website
                      kami) dari setiap perubahan dalam kontrol atau penggunaan
                      Informasi Pribadi, Informasi Pekerjaan atau berkas, atau
                      apabila Informasi tersebut menjadi tunduk pada kebijakan
                      privasi yang berbeda.
                    </li>
                    <li>
                      Kami mungkin atau kebijaksanaan kami, mengungkapkan
                      non-pribadi, agregat, atau Informasi lain non-personal
                      Anda, seperti statistik penggunaan Layanan kami.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(10)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md lg:text-lg text-sm w-full py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Cookies</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 10 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 10 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Kami menggunakan “cookies” untuk mengumpulkan informasi
                      dan meningkatkan layanan kami.
                    </li>
                    <li>
                      Cookie adalah file data kecil yang kami transfer ke
                      perangkat Anda. Kami dapat menggunakan “cookie ID session”
                      untuk mengaktifkan fitur tertentu dari Layanan, untuk
                      lebih memahami bagaimana Anda berinteraksi dengan Layanan
                      dan untuk memantau penggunaan agregat dan routing lalu
                      lintas web pada Layanan.
                    </li>
                    <li>
                      Kami juga dapat menggunakan “persistent cookies” untuk
                      menyimpan ID pendaftaran Anda dan login password untuk
                      login.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(11)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full lg:text-lg text-sm py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Larangan dalam menggunakan Aplikasi</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 11 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 11 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Anda tidak diperkenankan menggunakan WrkPln dalam hal:
                      <ul className="list-disc ml-5">
                        <li>
                          Mengubah, mengadaptasi atau meretas Layanan atau
                          dengan sengaja mengubah website lain untuk
                          mengindikasikan bahwa website tersebut memiliki
                          hubungan dengan Layanan kami.
                        </li>
                        <li>
                          Meniru, menduplikat, membuat salinan,
                          memperjual-belikan, menjual kembali atau memanfaatkan
                          bagian apapun dari Layanan, penggunaan Layanan, atau
                          akses ke Layanan tanpa izin tertulis dari WrkPln.
                        </li>
                        <li>
                          Akun yang mengandung konten yang melanggar hukum,
                          menghina, mengancam, memfitnah, mencemarkan nama baik,
                          pornografi, tidak senonoh, atau yang memberatkan atau
                          melanggar hak kekayaan intelektual pihak manapun atau
                          Ketentuan Layanan ini.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Penyalahgunaan dalam bentuk verbal, fisik, tulisan atau
                      penyalahgunaan lainnya (termasuk ancaman penyalahgunaan
                      atau meminta ganti rugi) dari pelanggan, karyawan, atau
                      anggota WrkPln dapat berakibat pada penghentian akun
                      secara langsung.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(12)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full lg:text-lg text-sm py-2 px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Ketentuan Lain</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 12 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 12 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>Kebijakan ini dapat berubah dari waktu ke waktu.</li>
                    <li>
                      Apabila kami melakukan perubahan terhadap Kebijakan
                      Privasi ini yang kami percaya secara material mengurangi
                      hak-hak Anda, kami akan memberikan pemberitahuan melalui
                      email atau posting perubahan tersebut di situs kami.
                    </li>
                    <li>
                      Kami dapat memberikan pemberitahuan perubahan keadaan lain
                      juga. Dengan terus menggunakan Layanan setelah perubahan
                      tersebut menjadi efektif, Anda setuju untuk terikat oleh
                      Kebijakan ini.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(13)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full py-2 px-4 lg:text-lg text-sm font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Perselisihan</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 13 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 13 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Kadang-kadang, kepemilikan Akun menjadi sengketa antara
                      satu atau lebih pihak, seperti perusahaan dan karyawan,
                      atau antar pemilik perusahaan. Kami mencoba untuk tidak
                      terlibat dalam perselisihan ini. Namun, kami berhak untuk
                      setiap saat apabila kami kehendaki, dan tanpa
                      pemberitahuan kepada Anda, untuk menentukan kepemilikan
                      akun yang sah dan/atau mentransfer akun ke pemilik yang
                      sah.
                    </li>
                    <li>
                      Apabila kami tidak bisa menentukan pemilik yang sah, kami
                      berhak untuk menangguhkan akun sampai pihak yang
                      bersengketa mencapai solusi.
                    </li>
                    <li>
                      Kami juga dapat meminta dokumentasi, seperti pas foto,
                      dokumen resmi yang dikeluarkan pemerintah, bukti
                      pembayaran ke Layanan, email yang digunakan pada
                      pendaftaran awal, untuk membantu menentukan pemilik yang
                      sah.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(14)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full py-2 lg:text-lg text-sm px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Tanggung Jawab</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 14 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 14 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      WrkPln tidak bertanggung jawab atas segala kerugian yang
                      bersifat langsung, tidak langsung, mendadak, khusus,
                      konsekuensial atau terhadap ganti rugi, termasuk tetapi
                      tidak terbatas pada, kehilangan keuntungan, goodwill,
                      penggunaan, data atau kerugian tidak berwujud lainnya
                      (meskipun sebelumnya WrkPln sudah diberitahu tentang
                      kemungkinan terjadinya kerugian tersebut), yang
                      disebabkan:
                      <ul className="list-disc ml-5">
                        <li>
                          Penggunaan atau ketidakmampuan menggunakan Layanan.
                        </li>
                        <li>
                          Biaya pengadaan barang dan jasa pengganti yang
                          dihasilkan dari barang, data, informasi atau layanan
                          yang dibeli atau didapatkan atau pesan yang diterima
                          atau transaksi yang masuk melalui atau dari Layanan.
                        </li>
                        <li>
                          Akses yang tidak sah atau penggantian transmisi atau
                          data Anda.
                        </li>
                        <li>
                          Pernyataan atau perilaku dari pihak ketiga dalam
                          Layanan.
                        </li>
                        <li>Hal apapun yang berkaitan dengan Layanan.</li>
                      </ul>
                    </li>
                    <li>
                      WrkPln tidak bertanggung jawab atas kesalahan dari hasil
                      perhitungan apabila:
                      <ul className="list-disc ml-5">
                        <li>
                          Data-data yang Anda masukkan pada sistem salah dan
                          tidak lengkap.
                        </li>
                        <li>
                          Adanya gangguan pada server dan koneksi internet.
                        </li>
                        <li>
                          Terjadi perubahan perhitungan PPh 21 dan/atau iuran
                          BPJS akibat peraturan baru dari otoritas yang
                          berwenang.
                        </li>
                      </ul>
                    </li>
                    <li>
                      WrkPln tidak bertanggung jawab atas segala kerugian atau
                      kerusakan yang disebabkan karena ketidakpatuhan Anda
                      dengan kewajiban keamanan akun Anda.
                    </li>
                    <li>
                      WrkPln tidak bertanggung jawab kepada Anda atau pihak
                      ketiga mana pun atas segala perubahan, perubahan harga,
                      penangguhan atau penghentian Layanan.
                    </li>
                    <li>
                      Kelalaian WrkPln untuk melaksanakan atau menerapkan setiap
                      hak atau ketetapan dari Ketentuan Layanan bukan merupakan
                      pengesampingan atas hak atau ketetapan tersebut.
                    </li>
                    <li>
                      Ketentuan Layanan merupakan seluruh perjanjian antara Anda
                      dan WrkPln dan mengatur penggunaan Layanan Anda,
                      menggantikan seluruh perjanjian sebelumnya antara Anda dan
                      WrkPln (termasuk, tetapi tidak terbatas pada, semua versi
                      terdahulu dari Ketentuan Layanan).
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(15)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full py-2 lg:text-lg text-sm px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Kebijakan Privasi (Geolocation)</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 15 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 15 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      dapat mengumpulkan data geolokasi Anda untuk menyediakan
                      fitur dan layanan berbasis lokasi di dalam aplikasi.
                      Informasi yang dapat dikumpulkan meliputi:
                      <ul className="list-disc ml-5">
                        <li>
                          Lokasi Anda yang akurat (GPS, Wi-Fi, atau data menara
                          seluler)
                        </li>
                        <li>Lokasi perkiraan untuk layanan umum</li>
                      </ul>
                    </li>
                    <li>
                      Bagaimana WrkPln Menggunakan Data Geolokasi
                      <ul className="list-disc ml-5">
                        <li>
                          Memberikan layanan yang dipersonalisasi berdasarkan
                          lokasi Anda
                        </li>
                        <li>
                          Meningkatkan pengalaman pengguna dan fungsi aplikasi
                        </li>
                        <li>Memenuhi persyaratan hukum atau peraturan.</li>
                      </ul>
                    </li>
                    <li>
                      WrkPln tidak akan membagikan data geolokasi Anda dengan
                      pihak ketiga kecuali,
                      <ul className="list-disc ml-5">
                        <li>Diperlukan oleh hukum atau proses hukum.</li>
                        <li>
                          Diperlukan untuk menyediakan layanan inti (misalnya,
                          layanan peta)
                        </li>
                        <li>
                          Anda telah memberikan persetujuan eksplisit untuk
                          pembagian tersebut.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Anda dapat mengontrol penggunaan data geolokasi Anda
                      dengan cara:
                      <ul className="list-disc ml-5">
                        <li>
                          Mengaktifkan atau menonaktifkan layanan lokasi melalui
                          pengaturan perangkat Anda.
                        </li>
                        <li>
                          Menarik persetujuan untuk akses data geolokasi kapan
                          saja.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Data geolokasi akan disimpan hanya selama diperlukan untuk
                      memenuhi tujuan pengumpulan atau sebagaimana diwajibkan
                      oleh hukum yang berlaku.
                    </li>
                    <li>
                      Kami menerapkan langkah-langkah keamanan yang sesuai untuk
                      melindungi data geolokasi Anda dari akses, perubahan, atau
                      pengungkapan yang tidak sah.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div
              className="container"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <div className="w-full px-3">
                <button
                  onClick={() => handleOpen(16)}
                  style={{ color: "#28a745" }}
                  className="flex justify-between shadow-md w-full py-2 lg:text-lg text-sm px-4 font-medium text-left bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                >
                  <span>Syarat dan Ketentuan (Geolocation)</span>
                  <span
                    className={`transform transition-all duration-500 ${
                      buka === 16 ? "rotate-180" : ""
                    }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.707 9.707a1 1 0 0 0-1.414-1.414L10 12.586 5.707 8.293a1 1 0 1 0-1.414 1.414l5 5a1 1 0 0 0 1.414 0l5-5z"
                      />
                    </svg>
                  </span>
                </button>
                <div
                  className={`${
                    buka === 16 ? "block" : "hidden"
                  } w-full px-4 mt-1 text-gray-700 bg-gray-100`}
                >
                  <ol
                    className="list-decimal m-2 lg:text-base text-xs font-extralight text-left gap-1"
                    style={{ color: "#4d4d4d" }}
                  >
                    <li>
                      Dengan menggunakan aplikasi ini, Anda menyetujui
                      pengumpulan dan penggunaan data geolokasi Anda sebagaimana
                      dijelaskan dalam Kebijakan Privasi kami.
                    </li>
                    <li>
                      Aplikasi ini bergantung pada layanan pihak ketiga
                      (misalnya, GPS, Wi-Fi, atau jaringan seluler) untuk data
                      geolokasi. Kami tidak menjamin keakuratan atau
                      ketersediaan layanan lokasi setiap saat.
                    </li>
                    <li>
                      WrkPln tidak bertanggung jawab atas masalah atau kerusakan
                      yang timbul dari:
                      <ul className="list-disc ml-5">
                        <li>Kesalahan dalam data geolokasi.</li>
                        <li>Penyalahgunaan layanan lokasi oleh Anda.</li>
                      </ul>
                    </li>
                    <li>
                      Kami dapat mengubah atau menghentikan fitur geolokasi
                      kapan saja tanpa pemberitahuan sebelumnya.
                    </li>
                    <li>
                      Tanggung Jawab Pengguna
                      <ul className="list-disc ml-5">
                        <li>
                          Memastikan layanan lokasi diaktifkan jika diperlukan
                          untuk fungsi aplikasi.
                        </li>
                        <li>
                          Menggunakan layanan geolokasi sesuai dengan hukum dan
                          peraturan setempat
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
