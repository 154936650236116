// import React from "react";
import tbm from "../../asset/img/bg/bg1.webp";
import aman1 from "../../asset/portfolio/security-1.webp";
import aman2 from "../../asset/portfolio/security-2.webp";
import aman3 from "../../asset/portfolio/security-3.webp";
import aman4 from "../../asset/portfolio/security-4.webp";

export const Company = () => {
  const text1 = {
    color: "#28a745",
  };
  const text2 = {
    color: "#ebf1f1",
  };

  return (
    <>
      <div className="relative md:pt-16 pt-14 md:pb-10 pb-14 content-center items-center justify-center">
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{ backgroundImage: `url(${tbm})` }}
        ></div>
        <div className="container relative mx-auto">
          <div className="w-full h-full">
            <div className="lg:py-20 lg:px-24 px-10 py-6">
              <div
                className="flex lg:text-center text-justify flex-col font-sans"
                data-aos="flip-left"
                data-aos-duration="2000"
              >
                <h2
                  className="md:text-4xl mb-2 text-center text-2xl"
                  style={text1}
                >
                  PT Teknologi Bangsa Mendunia
                </h2>
                <p className="mt-3 text-sm md:text-lg" style={text2}>
                  PT Teknologi Bangsa Mendunia merupakan suatu perseroan yang
                  didirikan berdasarkan hukum Negara Republik Indonesia yang
                  selanjutnya akan disebut sebagai (“kami”, dan “WrkPln”)
                </p>
                <p className="mt-3 text-sm md:text-lg" style={text2}>
                  Keamanan dan privasi pelanggan merupakan hal utama untuk
                  ditangani dengan baik. WrkPln terus menjaga keamanan dari
                  seluruh data dan informasi penting pelanggan. Server yang
                  terjamin, perlindungan data privasi, back-up berkala, serta
                  akses ke data pelanggan, merupakan upaya WrkPln untuk terus
                  menjaga keamanan dan privasi setiap pelanggan. WrkPln
                  menjaminkan bahwa seluruh informasi dan data Anda terenkripsi
                  dan terjaga dengan sangat baik.
                </p>
                <p className="mt-3 text-sm md:text-lg" style={text2}>
                  Aplikasi WrkPln merupakan dashboard yang dirancang untuk
                  mendukung kebutuhan HR, Sistem Penggajian dan Manajemen Tugas.
                  Aplikasi WrkPln menyediakan suatu sistem pada Layanan yang
                  dapat digunakan untuk menghitung jumlah pajak penghasilan (PPh
                  21), iuran BPJS, gaji, tunjangan lainnya (jika ada), klaim
                  biaya dan pengembalian biaya berdasarkan dari data yang
                  dimasukkan oleh Anda. Waktu Kerja adalah hari saat WrkPln
                  melakukan kegiatan operasionalnya, yaitu Hari Senin hingga
                  Jumat, kecuali libur nasional/publik, pukul 09.00- 18.00 WIB
                </p>
                <ul
                  className="ml-5 lg:ml-8 text-left list-decimal mt-3 text-sm md:text-lg"
                  style={text2}
                >
                  <li>
                    Paket Bronze merupakan paket yang disediakan oleh WrkPln
                    dengan fitur Sistem Sumber Daya Manusia, Sistem Pendukung,
                    &amp; Gratis Uji Coba 12 hari.
                  </li>
                  <li>
                    Paket Silver merupakan paket yang disediakan oleh WrkPln
                    dengan fitur Sistem Sumber Daya Manusia, Sistem Manajemen
                    Tugas, Sistem Pendukung, &amp; Gratis Uji Coba 12 hari.
                  </li>
                  <li>
                    Paket Gold merupakan paket yang disediakan oleh WrkPln
                    dengan fitur Sistem Sumber Daya Manusia, Sistem Manajemen
                    Tugas, Obrolan dan Pemberitahuan Langsung, Sistem Pendukung,
                    &amp; Gratis Uji Coba 12 hari.
                  </li>
                  <li>
                    Paket Custom merupakan paket yang disediakan oleh WrkPln
                    dengan fitur permintaan atau yang ingin digunakan oleh
                    Client &amp; Gratis Uji Coba 12 hari
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-100 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="items-center bg-gray-100">
        <div className="flex flex-wrap lg:py-10 py-5">
          <div className="w-full md:w-4/12">
            <div
              className="relative flex flex-col min-w-0 items-center w-full my-3 lg:mx-5"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                alt="WrkPln"
                src={aman1}
                loading="lazy"
                className="lg:w-full w-6/12 rounded-tr-3xl rounded-bl-3xl"
              />
            </div>
          </div>
          <div className="w-full md:w-5/12 text-center lg:mx-10">
            <h2
              className="lg:text-4xl text-2xl font-Roboto lg:text-left ml-4 leading-relaxed mt-2 mb-5"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              Keamanan Data Pusat{" "}
            </h2>
            <ul className="pl-4 lg:text-lg text-sm">
              <ul
                className="flex flex-col justify-between gap-1 ml-4 font-Roboto text-left list-decimal"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <li>
                  Infrastruktur fisik WrkPln ini menggunakan pusat data yang
                  dikelola oleh Google serta menggunakan teknologi Google Cloud
                  Platform (GCP). Pusat data Google telah diakreditasi sambil
                  terus mengelola risiko untuk memastikan kepatuhan dengan
                  standar industri.
                </li>
                <li>
                  Google sangat berpengalaman dalam membangun dan mengelola
                  pusat data berskala besar. Pengalaman ini telah diterapkan
                  pada platform dan infrastruktur GCP. Akses fisik maupun jarak
                  jauh dijaga ketat dengan standar keamanan tertinggi.
                </li>
                <li>
                  Untuk informasi tambahan, dapat dilihat di:{" "}
                  <span className="hover:underline hover:text-green-600">
                    https://cloud.google.com/security
                  </span>
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <svg
          className="relative bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="fill-current text-light-green-100"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="items-center bg-light-green-100">
        <div className="flex flex-wrap-reverse lg:py-10 py-5">
          <div className="w-full md:w-5/12 text-center lg:mx-10">
            <h2
              className="lg:text-4xl text-2xl font-Roboto lg:text-left ml-4 leading-relaxed mt-2 mb-5"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              Personal
            </h2>
            <ul className="pl-4 lg:text-lg text-sm">
              <ul
                className="flex flex-col justify-between gap-1 ml-4 font-Roboto text-left list-decimal"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <li>
                  WrkPln memiliki kebijakan privasi yang jelas dalam
                  mendefinisikan apa saja data yang dikumpulkan dan bagaimana
                  data tersebut digunakan. WrkPln berkomitmen untuk menjaga
                  privasi pelanggan serta memberikan transparansi atas bagaimana
                  WrkPln bekerja.
                </li>
                <li>
                  Kami mengambil langkah untuk melindungi privasi pelanggan kami
                  dengan melindungi data yang tersimpan dalam platform. WrkPln
                  menggunakan perlindungan yang melekat pada sistem lewat
                  otentikasi, kontrol akses, enkripsi transportasi data, serta
                  penggunaan HTTPS / SSL.
                </li>
              </ul>
            </ul>
          </div>
          <div className="w-full md:w-4/12">
            <div
              className="relative flex flex-col min-w-0 items-center w-full my-3 lg:mx-5"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              <img
                alt="WrkPln"
                src={aman2}
                loading="lazy"
                className="lg:w-full w-6/12 rounded-tr-3xl rounded-bl-3xl"
              />
            </div>
          </div>
        </div>
        <svg
          className="relative bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="fill-current text-green-100"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="items-center bg-green-100">
        <div className="flex flex-wrap lg:py-10 py-5">
          <div className="w-full md:w-4/12">
            <div
              className="relative flex flex-col min-w-0 items-center w-full my-3 lg:mx-5"
              data-aos="fade-right"
              data-aos-duration="2000"
            >
              <img
                alt="WrkPln"
                src={aman3}
                loading="lazy"
                className="lg:w-full w-6/12 rounded-tr-3xl rounded-bl-3xl"
              />
            </div>
          </div>
          <div className="w-full md:w-5/12 text-center lg:mx-10 md:pt-10">
            <h2
              className="lg:text-4xl text-2xl font-Roboto lg:text-left ml-4 leading-relaxed mt-2 mb-5"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              Backup{" "}
            </h2>
            <p
              className="lg:text-left lg:text-lg text-sm leading-normal mb-4 font-Roboto"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              Sistem dan database di WrkPln diback-up secara rutin dan disimpan
              di beberapa tempat serta menerapkan teknologi firewall untuk
              memastikan bahwa servis kami dan informasi Anda selalu aman dan
              tersedia, apa pun yang terjadi.
            </p>
          </div>
        </div>
        <svg
          className="relative bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="fill-current text-light-green-200"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="items-center bg-light-green-200">
        <div className="flex flex-wrap-reverse lg:py-10 py-5">
          <div className="w-full md:w-5/12 text-center lg:mx-10">
            <h2
              className="lg:text-4xl text-2xl font-Roboto lg:text-left ml-4 leading-relaxed mt-2 mb-5"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              Akses Data Pelanggan
            </h2>
            <p
              className="lg:text-left lg:text-lg text-sm leading-normal mb-4 font-Roboto"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              WrkPln menerapkan teknologi enkripsi dalam menjaga keamanan data
              dan informasi yang tersimpan sehingga dapat menjamin bahwa data
              Anda tidak akan diretas, dicuri, ataupun digunakan oleh
              pihak-pihak yang tidak bertanggung jawab yang tidak berhubungan
              dengan WrkPln.
            </p>
          </div>
          <div className="w-full md:w-4/12">
            <div
              className="relative flex flex-col min-w-0 items-center w-full my-3 lg:mx-5"
              data-aos="fade-down"
              data-aos-duration="2000"
            >
              <img
                alt="WrkPln"
                src={aman4}
                loading="lazy"
                className="lg:w-full w-6/12 rounded-tr-3xl rounded-bl-3xl"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
