import React from 'react';
import { Produk } from '../page/productpage';
import { Bagian } from '../page/footerpage';

export const ProdukPage = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <Produk />
            <Bagian />
        </>
    )
}