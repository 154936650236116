// import React from "react";
import wrkpln1 from "../../asset/img/splash/wrkpln1.webp";
import wrkpln2 from "../../asset/img/splash/wrkpln2.webp";
import icon1 from "../../asset/portfolio/icons/Icon4.webp";
import icon2 from "../../asset/portfolio/icons/Icon5.webp";

export const Platforms = () => {
  return (
    <>
      <div className="md:pb-0 pb-3">
        <div className="w-full h-full" style={{ backgroundColor: "#ebf1f1" }}>
          <div className="items-center">
            <div className="container font-Roboto ">
              <div className="flex flex-wrap-reverse">
                <div
                  className="w-full md:w-5/12 px-4 mr-auto ml-auto"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <div className="p-4 lg:mt-8 text-center inline-flex items-center justify-center w-14 h-14 shadow-lg rounded-full bg-white">
                    <img src={icon1} alt="WrkPln" />
                  </div>
                  <h3
                    className="lg:text-2xl text-base font-semibold mb-2 mt-1 leading-normal"
                    style={{ color: "#fd7e14" }}
                  >
                    Transform
                  </h3>
                  <p
                    className="lg:text-xl text-sm font-semibold leading-relaxed mt-2 mb-2"
                    style={{ color: "#333333" }}
                  >
                    {" "}
                    Your Workforce Productivity
                  </p>
                  <p
                    className="lg:text-lg text-xs text-justify md:text-left leading-relaxed mt-4 mb-2"
                    style={{ color: "#4d4d4d" }}
                  >
                    {" "}
                    For Human Resources to be a strategic business partner,
                    companies need a solid foundation for their core processes.
                    At{" "}
                    <span
                      style={{ color: "#28a745" }}
                      className="font-semibold"
                    >
                      WrkPln
                    </span>{" "}
                    we believe that Human Resources cannot become strategic
                    until it excels in operations. That's because a successful
                    strategy is based on effective day-to-day operations
                  </p>
                </div>
                <div
                  className="w-full md:w-3/12 px-4 mr-auto ml-auto"
                  data-aos="zoom-in-up"
                  data-aos-duration="1500"
                >
                  <div
                    className="relative flex flex-col md:mt-10 min-w-0 break-words items-center w-full mb-3 mt-3"
                    style={{ backgroundColor: "#ebf1f1" }}
                  >
                    <img
                      alt="WrkPln"
                      src={wrkpln1}
                      loading="lazy"
                      className="lg:w-full w-7/12 align-middle"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="flex flex-wrap">
                <div
                  className="w-full md:w-3/12 px-4 mr-auto ml-auto"
                  data-aos="zoom-in-down"
                  data-aos-duration="1500"
                >
                  <div
                    className="relative flex flex-col md:mt-9 min-w-0 break-words items-center w-full mb-3"
                    style={{ backgroundColor: "#ebf1f1" }}
                  >
                    <img
                      alt="WrkPln"
                      src={wrkpln2}
                      loading="lazy"
                      className="lg:w-full w-9/12 align-middle"
                    />
                  </div>
                </div>
                <div
                  className="w-full md:w-5/12 px-4 mr-auto ml-auto"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <div className="p-4 lg:mt-8 text-center inline-flex items-center justify-center w-14 h-14 shadow-lg rounded-full bg-white">
                    <img src={icon2} alt="WrkPln" />
                  </div>
                  <h3
                    className="lg:text-2xl text-base font-semibold mb-2 mt-1 leading-normal"
                    style={{ color: "#fd7e14" }}
                  >
                    Upgrade
                  </h3>
                  <p
                    className="lg:text-xl text-sm font-semibold leading-relaxed mt-2 mb-2"
                    style={{ color: "#333333" }}
                  >
                    Your Company's Efficiency
                  </p>
                  <p
                    className="lg:text-lg text-xs text-justify md:text-left leading-relaxed mt-4 mb-2"
                    style={{ color: "#4d4d4d" }}
                  >
                    In modern business era, a reliable, effecive and efficient
                    Human Resource Management System is a crucial needs for
                    every company with active day-to-day tasks.{" "}
                    <span
                      style={{ color: "#28a745" }}
                      className="font-semibold"
                    >
                      WrkPln
                    </span>{" "}
                    thrives to help you and your business with our advanced
                    Human Capital Management System accustomed to your company's
                    specific needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
