import React from 'react';
import { Terms } from '../page/termspage';
import { Bagian } from '../page/footerpage';

export const TermsPage = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <Terms />
            <Bagian />
        </>
    )
}