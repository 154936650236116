// import React from "react";
import heros from "../../asset/img/splash/hero.webp";
import bg from "../../asset/img/bg/platform.webp";

export const Heros = () => {
  return (
    <div
      className="relative md:pt-16 pt-14 md:pb-10 pb-14 flex content-center items-center justify-center"
      style={{ minHeight: "75vh" }}
    >
      <div
        className="absolute top-0 w-full h-full bg-center bg-cover"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <span className="w-full h-full absolute bg-opacity-30 bg-brown-700"></span>
      </div>
      <div className="container relative mx-auto">
        <div className="items-center flex md:flex-row-reverse flex-col">
          <div className="w-full md:w-7/12 px-4 ml-auto mr-auto text-center">
            <div className="md:pr-12 md:text-left" data-aos="fade-down">
              <h1
                className="lg:text-3xl text-2xl pt-3 md:leading-normal font-sans leading-10 lg:font-semibold"
                style={{ color: "#44cc77" }}
              >
                Let's optimize your people productivity with us
              </h1>
              <h4
                className="lg:text-xl text-sm font-sans md:leading-normal leading-5 mt-4"
                style={{ color: "#d8d8d8" }}
              >
                WrkPln is a human resource management solution that supports the
                needs of HR Systems, Payroll Systems and Task Management. WrkPln
                integrates a real-time system that will support and optimize
                your business and productivity
              </h4>
            </div>
          </div>
          <div className="flex-1 mt-8 flex items-center justify-center h-full">
            <img
              src={heros}
              alt="wrkpln"
              loading="lazy"
              className="lg:w-9/12 w-3/5 lg:mr-8 mb-6 lg:mb-0"
              data-aos="zoom-in"
              data-aos-duration="1500"
            ></img>
          </div>
        </div>
      </div>
      <svg
        className="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className="fill-current"
          style={{ color: "#ebf1f1" }}
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  );
};
