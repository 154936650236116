import { useState } from "react";
import contact from "../../asset/img/bg/bg2.webp";
import emailjs from "emailjs-com";
import {
  EMAILJS_SERVICE_ID,
  EMAILJS_USER_ID,
  EMAILJS_TEMPLATE_ID,
} from "../ap/fc_contact";

export const Kontak = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    pilihan: "",
    company: "",
    pesan: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    emailjs
      .send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, data, EMAILJS_USER_ID)
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setData({
            name: "",
            email: "",
            phoneNumber: "",
            pilihan: "",
            company: "",
            pesan: "",
          });
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <>
      <div className="w-full h-full ">
        <div
          className="relative top-0 py-24 bg-center bg-cover"
          style={{ backgroundImage: `url(${contact})` }}
        >
          <span className="opacity-60 bg-transparent grayscale"></span>
          <div className="flex items-center flex-col justify-between">
            <h2
              className="md:text-4xl text-2xl"
              style={{ color: "#28a745", fontFamily: "Roboto" }}
            >
              Contact Us
            </h2>
            <p
              className="mt-3 text-base md:text-xl"
              style={{ color: "#d8d8d8", fontFamily: "Roboto" }}
            >
              Thank you for your interest and keep growing with us
            </p>
          </div>
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <form onSubmit={handleSubmit} className="container mx-auto px-4 h-full">
          <div className="-mt-14 -mb-12 flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-9/12 px-4">
              <div className="rounded-lg bg-gray-300 relative border-0 flex-col min-w-0 break-words w-full mb-6 shadow-2xl flex">
                <div className="flex-auto px-4 lg:px-10 py-10 pt-10">
                  <div className="text-center mb-7">
                    <h6
                      className="md:text-xl text-base"
                      style={{ color: "#252525", fontFamily: "Roboto" }}
                    >
                      Fill the below and our representative will get back to you
                      as soon as possible
                    </h6>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block md:text-base text-xs mb-2 md:mb-0 pr-4"
                      style={{ color: "#343a40", fontFamily: "sans-serif" }}
                      htmlFor="inline-full-pilihan"
                    >
                      What problems can we help you with?
                    </label>
                    <select
                      className="bg-gray-200 md:text-base text-xs appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 h-12 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                      style={{ color: "#343a40", fontFamily: "sans-serif" }}
                      id="inline-full-pilihan"
                      value={data.pilihan}
                      onChange={handleChange}
                      name="pilihan"
                      required
                    >
                      <option value="" className="text-center">
                        --Select Category--
                      </option>
                      <option value="General">New Inquiry</option>
                      <option value="Issue">Technical Issue</option>
                      <option value="Billing">Billing Issue</option>
                      <option value="Support">Customer Support</option>
                      <option value="Bussiness">Partnership & Bussiness</option>
                    </select>
                  </div>
                  <div className="container">
                    <div className="flex md:flex-row flex-col md:gap-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block md:text-base text-xs mb-2 md:mb-0 pr-4"
                          style={{ color: "#343a40", fontFamily: "sans-serif" }}
                          htmlFor="inline-full-name"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          className="bg-gray-200 md:text-base text-xs appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 h-12 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                          style={{ color: "#343a40", fontFamily: "sans-serif" }}
                          id="inline-full-name"
                          placeholder="Your Name"
                          value={data.name}
                          onChange={handleChange}
                          name="name"
                          required
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block md:text-base text-xs mb-2 md:mb-0 pr-4"
                          style={{ color: "#343a40", fontFamily: "sans-serif" }}
                          htmlFor="inline-company"
                        >
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="bg-gray-200 md:text-base text-xs appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 h-12 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                          style={{ color: "#343a40", fontFamily: "sans-serif" }}
                          id="inline-company"
                          placeholder="Company Name"
                          value={data.company}
                          onChange={handleChange}
                          name="company"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <div className="flex md:flex-row flex-col md:gap-4">
                      <div className="relative w-full mb-3">
                        <label
                          className="block md:text-base text-xs mb-2 md:mb-0 pr-4"
                          style={{ color: "#343a40", fontFamily: "sans-serif" }}
                          htmlFor="inline-phone-number"
                        >
                          Phone Number
                        </label>
                        <div className="flex flex-row">
                          <select
                            aria-label="Country"
                            className="focus:border-r border-r border-green-500 focus:rounded focus:outline-none bg-gray-200 rounded-l"
                            style={{
                              color: "#343a40",
                              fontFamily: "sans-serif",
                            }}
                          >
                            <option
                              className="md:text-base text-xs"
                              value="+62"
                            >
                              +62
                            </option>
                            <option className="md:text-base text-xs" value="+1">
                              +1
                            </option>
                            <option
                              className="md:text-base text-xs"
                              value="+44"
                            >
                              +44
                            </option>
                            <option
                              className="md:text-base text-xs"
                              value="+60"
                            >
                              +60
                            </option>
                            <option
                              className="md:text-base text-xs"
                              value="+61"
                            >
                              +61
                            </option>
                            <option
                              className="md:text-base text-xs"
                              value="+63"
                            >
                              +63
                            </option>
                            <option
                              className="md:text-base text-xs"
                              value="+65"
                            >
                              +65
                            </option>
                            <option
                              className="md:text-base text-xs"
                              value="+82"
                            >
                              +82
                            </option>
                          </select>
                          <div className="relative w-full">
                            <input
                              type="tel"
                              className="bg-gray-200 md:text-base text-xs appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 h-12 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                              style={{
                                color: "#343a40",
                                fontFamily: "sans-serif",
                              }}
                              id="inline-phone-number"
                              placeholder="123-456-789"
                              value={data.phoneNumber}
                              onChange={handleChange}
                              name="phoneNumber"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block md:text-base text-xs mb-2 md:mb-0 pr-4"
                          style={{ color: "#343a40", fontFamily: "sans-serif" }}
                          htmlFor="inline-email"
                        >
                          Your email address
                        </label>
                        <input
                          type="email"
                          className="bg-gray-200 md:text-base text-xs appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 h-12 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                          style={{ color: "#343a40", fontFamily: "sans-serif" }}
                          id="inline-email"
                          placeholder="email@example.com"
                          value={data.email}
                          onChange={handleChange}
                          name="email"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block md:text-base text-xs mb-2 md:mb-0 pr-4"
                      style={{ color: "#343a40", fontFamily: "sans-serif" }}
                      htmlFor="inline-pesan"
                    >
                      Tell us more about what we can help you with
                    </label>
                    <textarea
                      className="bg-gray-200 md:text-base text-xs appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 lg:h-32 h-24 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                      style={{ color: "#343a40", fontFamily: "sans-serif" }}
                      id="inline-pesan"
                      placeholder="Please Type here"
                      value={data.pesan}
                      onChange={handleChange}
                      name="pesan"
                      required
                    ></textarea>
                  </div>
                  <div className="text-center px-4 py-8">
                    <button
                      type="submit"
                      className="shadow focus:shadow-outline cursor-pointer w-auto focus:outline-none font-semibold text-base py-3 px-5"
                      style={{
                        backgroundColor: "#28a745",
                        color: "#d8d8d8",
                        borderRadius: "40px",
                      }}
                    >
                      <span className="flex flex-row gap-3">
                        Submit
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
